<script setup>
import { ref, computed, onMounted, nextTick, watch } from "vue";
import httpClient from "@/shared/services/http-client";
import QSelectNoItems from "./QSelectNoItems.vue";

const props = defineProps({
    // The value of the select component, required for two-way binding
    modelValue: {
        required: true,
    },
    // The API endpoint from which data needs to be fetched
    // Example: `/iotapp/assets/`
    api: {
        type: String,
        required: true,
    },
    // Determines which key from the fetched data is used for the search query
    // By default, the 'name' key will be used
    searchKey: {
        type: [String, Array],
        required: false,
    },
    // Allows handling complex API queries such as grouping, etc.
    // Custom query params to append to the API endpoint
    // Example: `&groupBy=event&excludeFlag=true&type`
    customQuery: {
        type: String,
        required: false,
        default: "",
    },
    // Label displayed in the select box
    label: {
        type: String,
        required: true,
    },
    // Function or string used to customize the display of options
    optionLabel: [Function, String],
    // Function or string used to specify the value property of options
    optionValue: [Function, String],

    // Whether to emit the selected value, useful for controlled components
    emitValue: {
        type: Boolean,
        default: false,
    },
    // Whether the select box has an error state
    error: {
        type: Boolean,
        default: false,
    },
    // Error message to display when there is an error
    errorMessage: {
        type: String,
        default: "",
    },
    // Whether the select box is required
    required: {
        type: Boolean,
        default: false,
    },
    // Whether multiple options can be selected
    multiple: {
        type: Boolean,
        default: false,
    },
    // Whether to use chips for multiple selection
    useChips: {
        type: Boolean,
        default: false,
    },
    // Whether to use dense mode for the select box
    dense: {
        type: Boolean,
        default: false,
    },
    // Whether the select box is clearable
    clearable: {
        type: Boolean,
        default: false,
    },
    // Array of IDs to exclude from the options list
    excludeList: {
        type: Array,
        default: () => [],
    },
    // Whether the select box is outlined
    outlined: {
        type: Boolean,
        default: false,
    },
    // Whether to hide the bottom space of the select box
    hideBottomSpace: {
        type: Boolean,
        default: false,
    },
    // Prop used to fetch the model item if it's not present in the options list
    fetchModelItem: String,
    // Prop used for local search functionality works only if fetchModelItem prop available and required if it present
    // Helps fetch items from the database based on search criteria
    localSearch: String,
    // extra option will persist on every cases and will not be cleared
    appendOptions: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue"]);

const selectedModel = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

// declarations
let optionsList = ref([...props.appendOptions]);
const pagination = ref({
    page: 1,
    pageSize: 15,
    loading: false,
    searchText: "",
    lastPage: 0,
    debounce: null,
});
// watchers
watch(
    () => props.excludeList,
    async (newExcludeList) => {
        const excludedIds = new Set(newExcludeList.map((e) => e));
        if (pagination.value.debounce) clearTimeout(pagination.value.debounce);
        pagination.value.debounce = setTimeout(async () => {
            optionsList.value = [];
            pagination.value = {
                page: 1,
                pageSize: 15,
                hasNext: true,
                loading: false,
                searchText: "",
                debounce: null,
            };
            await getOptionsList();
            const filteredOptionsList = optionsList.value.filter((entry) => !excludedIds.has(entry.id));
            optionsList.value = filteredOptionsList;
        }, 500);
    },
);

// functions
const getOptionsList = async () => {
    pagination.value.loading = true;
    try {
        const baseurl = props.api;
        const query = constructQuery();
        const response = await httpClient.get(`${baseurl}${query}`);
        if (response.status === 200) {
            const data = response.data.contents;
            optionsList.value.push(
                ...data.filter((item) => {
                    const labelValue =
                        typeof props.optionLabel === "function"
                            ? props.optionLabel(item)
                            : item[props.optionLabel];
                    return Boolean(labelValue);
                }),
            );
            pagination.value.lastPage = response.data.totalCount / pagination.value.pageSize;
        }
    } catch (error) {
        console.error(error);
    } finally {
        pagination.value.loading = false;
    }
};

const constructQuery = () => {
    let queryParams = `?page=${pagination.value.page}&limit=${pagination.value.pageSize}${props.customQuery}`;
    const value = encodeURIComponent(pagination.value.searchText);
    if (value) {
        queryParams += `&searchText=${value}`;
        let keys = props.searchKey || ["name"];

        if (Array.isArray(keys) == false) {
            keys = [keys];
        }
        queryParams += `&searchKeys=${keys.join()}`;
    }

    return queryParams;
};

const vScrollOptions = ({ to, ref }) => {
    const lastIndex = optionsList.value.length - 1;
    if (
        pagination.value.loading !== true &&
        pagination.value.page < pagination.value.lastPage &&
        to === lastIndex
    ) {
        pagination.value.loading = true;
        pagination.value.debounce = setTimeout(async () => {
            pagination.value.page++;
            await getOptionsList().then(() => {
                nextTick(() => {
                    ref.refresh();
                });
            });
        }, 500);
    }
};

const searchFromOptions = (search) => {
    if (pagination.value.debounce) clearTimeout(pagination.value.debounce);
    pagination.value.debounce = setTimeout(() => {
        optionsList.value = [...props.appendOptions];
        pagination.value = {
            page: 1,
            pageSize: 15,
            hasNext: true,
            loading: false,
            searchText: search,
            debounce: null,
        };
        getOptionsList();
    }, 500);
};

const fetchCurrentItemAndReplaceModel = async (multi = false, index) => {
    try {
        const findQuery = props.fetchModelItem;
        let queryParams = `?page=1&limit=1&${findQuery}=${
            multi ? encodeURIComponent(props.modelValue[index]) : encodeURIComponent(props.modelValue)
        }${props.customQuery}`;
        const baseurl = props.api;
        const response = await httpClient.get(`${baseurl}${queryParams}`);
        if (response.status == 200) {
            const data = response.data.contents;
            if (data?.length > 0) {
                if (multi) {
                    selectedModel.value[index] = data[0];
                } else {
                    selectedModel.value = data[0];
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
};

const checkIsValueExistInOptionsData = async () => {
    const checkKey = props.localSearch;
    const checkVal = props.modelValue;

    if (Array.isArray(checkVal)) {
        for (const [index, val] of checkVal.entries()) {
            if (!(await checkValueExistsInOptionsList(checkKey, val))) {
                await fetchCurrentItemAndReplaceModel(true, index);
            }
        }
    } else {
        if (!(await checkValueExistsInOptionsList(checkKey, checkVal))) {
            await fetchCurrentItemAndReplaceModel();
        }
    }
};

const checkValueExistsInOptionsList = async (nestedKey, value) => {
    for (const obj of optionsList.value) {
        if (checkNestedKeyValue(obj, nestedKey, value)) {
            return true;
        }
    }
    return false;
};

const checkNestedKeyValue = (object, nestedKey, value) => {
    const keys = nestedKey.split("__");
    let currentObj = object;
    for (const key of keys) {
        if (currentObj && typeof currentObj === "object" && key in currentObj) {
            currentObj = currentObj[key];
        } else {
            return false;
        }
    }
    return currentObj === value;
};

const getCurrentItem = async () => {
    if (props.modelValue) {
        checkIsValueExistInOptionsData();
    }
};

onMounted(async () => {
    await getOptionsList();
    // The following block ensures that the selected item is fetched and set in the model, especially if it's not present in the options list.
    // This behavior is controlled by the fetchModelItem prop, which determines whether to fetch the selected item from the database based on the localSearch prop.
    // If fetchModelItem is enabled and the selected item is not found in the options list, getCurrentItem is triggered to fetch and replace the model value.
    if (props.fetchModelItem) {
        await getCurrentItem();
    }
});
</script>

<template>
    <q-select
        :data-testid="`gSelect__${label}`"
        :outlined="outlined"
        :clearable="clearable"
        :dense="dense"
        label-slot
        :hide-bottom-space="hideBottomSpace"
        v-model:model-value="selectedModel"
        :options="optionsList"
        :option-label="optionLabel"
        :option-value="optionValue"
        :emit-value="emitValue"
        :error="error"
        :error-message="errorMessage"
        use-input
        :multiple="multiple"
        :use-chips="useChips"
        @virtual-scroll="vScrollOptions"
        @input-value="searchFromOptions"
        :loading="pagination.loading">
        <template v-slot:label> {{ label }} <span class="text-red" v-if="required">*</span> </template>
        <template v-slot:no-option>
            <QSelectNoItems />
        </template>
        <template v-slot:prepend>
            <slot name="prepend"></slot>
        </template>
    </q-select>
</template>
